import React, { Component } from "react";
import Img from "gatsby-image";

export default class Resume extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="section resumeColor " id="Testimonials">
        <div className="container">
          <div className="section-head text-center">
            <h2>Resume</h2>
          </div>
            {data.edges.map((item, index) => {
              return (
                <>
                <a 
                  href={item.node.subTitle}
                  target="_blank"
                  rel="noreferrer"
                  >
                <div key={index} className="testimonials-item">
                  <div className="testi-inner">
                    <Img
                      className="avatar"
                      fluid={item.node.avatarImage.fluid}
                      objectFit="cover"
                      objectPosition="50% 50%"
                    />
                    <h3 className="Resume">Click to view</h3>

                  </div>
                </div>
                </a>
                </>
              );
            })}
        </div>
      </div>
    );
  }
}
