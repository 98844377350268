import React, { Component } from "react";
import Img from "gatsby-image";
import {Card} from 'semantic-ui-react'

export default class Projects extends Component {
  render() {
    const { data } = this.props;
    return (
      <div className="work section" id="Work">
        <div className="container">
          <div className="section-head">
            <h2 className="text-center">Projects</h2>
          </div>
          <ul className="work-list">
            {data.edges.reverse().map((item, index) => {
              return (
                <li key={index} className="item">
                  <div className="inner">
                    <a href={item.node.url} target="_blank">
                    <Card.Group>
                    <Card>
                      <Img
                        fluid={item.node.image.fluid}
                        objectFit="cover"
                        objectPosition="50% 50%"
                      />
                      <span className="name">{item.node.siteName}</span>
                        <Card.Content>
                          <Card.Header>Description:</Card.Header>
                            <Card.Description>{item.node.description}</Card.Description>
                        </Card.Content>

                    </Card>
                    </Card.Group>
                    </a>
                  </div>
                </li>
              );
            })}
          </ul>
          <h3 className="Disclaimer">Disclaimer: The apps are hosted on Heroku so they take a little while to load</h3>
        </div>
      </div>

    );
  }
}
