import React, {useState} from "react";
import Img from "gatsby-image";
import 'semantic-ui-css/semantic.min.css'
import {Button} from 'semantic-ui-react'

export default function Language({data}){
  const [japanese, setJapanese] = useState(false)
  const [english, setEnglish] = useState(true)

const changeJapanese = () => {
  setJapanese(true)
  setEnglish(false)
}

const changeEnglish = () => {
  setEnglish(true)
  setJapanese(false)
}

return (
  <div className="about section" id="About">
    <div className="container">
      <div className="about-main row">
        <div className="left col-md-5 col-lg-4 mb-3">
          <Img
            fluid={data.photo.fluid}
            objectFit="cover"
            objectPosition="top center"
          />
        </div>

        {japanese === true && <div className="left col-md-7 col-lg-8">
          <div className="about-details">
            <span className="name">僕の名前は得万寿(エバンス)　武莉帝(ブリデル) です。
            <Button.Group>
              <Button primary onClick={()=> changeEnglish()}>English</Button>
              <Button.Or />
              <Button positive onClick={()=> changeJapanese()}>Japanese</Button>
            </Button.Group>
            </span>

            <h2 className="sub-position">
              プロのフルスタックソフトウェアエンジニアです。
            </h2>
            <div>
              <h4 className="explanation">
              インストラクショナルデザインのバックグラウンドを活用して、問題の論理的な解決策を特定するために、段階的な方法論を使用します。 僕は細部に気を配り、ペースの速い環境でプロジェクトの締め切りに間に合わせることができます。 僕は常にチャレンジをし、目標に向かいながら臨機応変に対応することができます。
              </h4>
              <h4 className="explanation">
              趣味はパズルしたり、ボウリングをしたり、テレビゲームをしたり、音楽制作をするのが大好きです。僕は日本が大好きで、交換留学生、留学、英語教師として計5年間住んでいました。 また、上級レベルの日本語を話すことができます。 ポートフォリオを楽しんでいただければ幸いです！
              </h4>
            </div>
            <ul className="details">
              <li>
                <strong>フルネーム</strong>
                <p>{data.name}</p>
              </li>
              <li>
                <strong>場所</strong>
                <p>{data.location}</p>
              </li>
              <li>
                <strong>Eメール</strong>
                <p>
                  <a href={`mailto:${data.gmail}`}>{data.gmail}</a>
                </p>
              </li>
            </ul>
            <div className="socials">
              <ul>
                <li>
                  <a
                    className="fab fa-linkedin-in"
                    href={data.linkdin}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
                <li>
                  <a
                    className="fab fa-github"
                    href={data.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>}

        {english===true && <div className="left col-md-7 col-lg-8">
          <div className="about-details">
            <span className="name">My Name is {data.name}.
            <Button.Group>
              <Button primary onClick={()=> changeEnglish()}>English</Button>
              <Button.Or />
              <Button positive onClick={()=> changeJapanese()}>Japanese</Button>
            </Button.Group>
            </span>

            <h2 className="sub-position">
              I'm a Professional {data.designation}.
            </h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.description.childMarkdownRemark.html
              }}
            />
            <ul className="details">
              <li>
                <strong>Full Name</strong>
                <p>{data.name}</p>
              </li>
              <li>
                <strong>Location</strong>
                <p>{data.location}</p>
              </li>
              <li>
                <strong>Email</strong>
                <p>
                  <a href={`mailto:${data.gmail}`}>{data.gmail}</a>
                </p>
              </li>
            </ul>
            <div className="socials">
              <ul>
                <li>
                  <a
                    className="fab fa-linkedin-in"
                    href={data.linkdin}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
                <li>
                  <a
                    className="fab fa-github"
                    href={data.github}
                    target="_blank"
                    rel="noopener noreferrer"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>}
      </div>
    </div>
  </div>
)
};
